.DecorationItemCard {
    background-color: aliceblue;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: solid;
    border-width: 2vw;
    border-color: cornflowerblue;
}

.DecorationItemBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    object-fit: fill;
}

.DecorationItemImage {
    object-fit: cover;
    height: calc(20vh + 200px);
    width: calc(20vw + 200px);
    padding: 5px;
}

.DecorationItemCartOptions {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: aliceblue;
    margin-bottom: 0px;
}

.DecorationItemInfo{
    display: none;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 20vw;
}

.CartButton {
    width: 30px;
    color: white;
    background-color: #61dafb;
    margin: 5px;
    padding: 5px;
    text-decoration: none;
    border-color: white;
    border-radius: 11px;
}

.CartButton:hover {
    color:hotpink;
    border-color: hotpink;
}

/* Popup style */
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
   
  .box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
   
  .close-icon {
    display: none;
  }

@media only screen and (max-width: 240px) {
    .DecorationItemCartOptions {
        flex-direction: column-reverse;
    }
  }

@media only screen and (max-width: 320px) {
    .DecorationItemInfo{
        display: none;
    }
    .MoreInfoButton {
        display: contents;
    }
    .DecorationItemCard {
        flex-direction: column;
    }
  }

