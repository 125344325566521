@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.PageHeader {
    padding-top: 7vh;
    text-align: center;
    color: white;
}

.HomeownerPage {
    background-color: #282c34;
}

.HomeownerBody {
    padding-bottom: 7vh;
}

.AuthenticationForm {
    color: #61dafb;
    text-align: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 3vh;
}

.SignInGreeting {
  padding-bottom: 4vh;
}

.Button, .CheckoutButton {
    color: white;
    background-color: #61dafb;
    margin: 15px;
    padding: 5px;
    text-decoration: none;
    border: solid;
    border-color: white;
    border-radius: 11px;
}

.Button:hover, .CheckoutButton:hover  {
  color: hotpink;
  border-color: hotpink;
}
.HomeownerHomeContent {
    padding-bottom: 15vh;
}

.Nav {
  text-align: center;
  background-color: #5b8df1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.NavLinks, .FooterNavLinks {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.NavLink0 {
    text-decoration: none;
    color: white;
}

.NavLink1 {
    text-decoration: none;
    color: white;
    padding: 5px;
    margin: 5px;
    font-size: calc(7px + 2vmin);
    border-style: double;
    border-width: 7px;
    border-radius: 10px;
    border-color: white;
    background-color:hotpink;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

#Nav1:hover {
    text-decoration: none;
    color: hotpink;
    padding: 5px;
    margin: 5px;
    font-size: calc(7px + 2vmin);
    border-style: double;
    border-width: 7px;
    border-radius: 10px;
    border-color: hotpink;
    background-color:rgb(169, 219, 248);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.NavLink2 {
    text-decoration: none;
    color: hotpink;
    padding: 5px;
    margin: 5px;
    font-size: calc(7px + 2vmin);
    border-style: double;
    border-width: 7px;
    border-radius: 10px;
    border-color: hotpink;
    background-color: white;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

#Nav1:hover, #Nav2:hover {
    text-decoration: none;
    color: white;
    padding: 5px;
    margin: 5px;
    font-size: calc(7px + 2vmin);
    border-style: double;
    border-width: 7px;
    border-radius: 10px;
    border-color: white;
    background-color:rgb(169, 219, 248);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.App-link {
  color: #61dafb;
}

.TextLogo {
  padding-left: 1vmin;
  font-family: Pacifico;
}

.TextLogo:hover {
  color: hotpink;
}

.NavLogo {
  padding: 1vmin;
  height: 77px;
  width: 77px;
  border-radius: 20px;
}

.NavLogo:hover {
  background-color: hotpink;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #5b8df1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-body, .BrowsingHead {
  background-color: #5b8df1;
  min-height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.BrowsingPage {
  text-align: center;
  height: 100%;
}

.BrowsingHead {
  background-color: rgb(167, 128, 219);
}

.NewBrowsingHead {
  background-color: #282c34;
  color: aliceblue;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
}

.BulletinList {
  text-align: cemter;
  padding-bottom: 10vh;
}

.Footer {
    border-top: 40vh solid #61dafb;
    background-color: #282c34;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.NameBar {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 10vmin);
  color: white;
  font-family: Pacifico;
}

.App-link {
  color: #61dafb;
}

.Home-logo {
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.Cartoon {
  height: calc(47vmin + 95px);
  padding-top: 50px;
  padding-bottom: 50px;
}

.form {
  padding-bottom: 100px;
}

.form-control {
  width: 100%;
  height: 22px;
}

.GuestZipForm { 
  display: flex;
  flex-direction: column;
  color: #282c34;
  padding-top: 20vh;
  padding-bottom: 20vh;
  background-color: #282c34;
}

.GuestZipFormBody {
  background-color: #61dafb;
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.GuestZipFormInput {
  padding-left: 1vw;
}

.GuestBackgroundWrapper {
  background-color: white;
  max-width: max-content;
  margin-left:auto;
  margin-right: auto;
  padding: 7px;
  border: solid;
  border-color: #282c34;
  border-radius: 15px;
  border-width: 2vh;
}

.ShoppingScreen {
  display: flex;
  flex-direction: row;
  height: 70vh;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: cornflowerblue;
  justify-content: space-evenly;
}

.DecorationGridArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: cornflowerblue;
  overflow-y: scroll;
  white-space: nowrap;
  height: 100%;
  width: 60%;
  border: solid;
  border-color: #282c34;
  border-radius: 15px;
  border-width: 2vh;
}

.CartContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: aliceblue;
  color: black;
  height: 100%;
  width: 30%;
  padding-left: 2vw;
  padding-right: 2vw;
  padding-left: 1vw;
  padding-right: 1vw;
  border: solid;
  border-color: aliceblue;
  border-width: 10px;
  border-radius: 5px;
}

.CartWrapper {
 display: flex;
 flex-direction: column;
 width: 100%
}

.ShoppingCartSection1 {
  height: 25vh;
  padding-left: 1vw;
  padding-right: 1vw;
  overflow-y: scroll;
  white-space: nowrap;
  background-color: white;
  border: solid;
  border-color: gray;
  border-width: 1px;
}

.InnerCheckoutSection {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
  color: black;
}

.InnerCheckoutSection {
  width: calc(25vw + 200px);
  margin: auto;
  margin-top: 10px; 
  margin-bottom: 10px;
  border:solid;
  border-color: #282c34;
  border-width: 3px;
  padding: 3vh;
  padding-top: 1vh;
  background-color: white;
}

#OrderButton {
  background-color: #282c34;
}

.OrderConfirmPage {
  text-align: center;
  background-color: #61dafb;
}

.OrderConfirmDiv {
  margin-top: 15vh;
  background-color: #61dafb;
}

.Checkout {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #282c34;
  color: #282c34;
}

.CheckoutBullets {
  list-style-type: none; 
  padding: 0;
  margin: 0;
  text-align: left;
}

.InstallationsBox {
  height: 25vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
}

.Installation {
  margin: 20px;
  padding: 20px;
  background-color: white;
}

.OrderButton {
  color: white;
  background-color: #61dafb;
  margin: 15px;
  padding: 5px;
  text-decoration: none;
  border: solid;
  border-color: white;
  border-radius: 11px;  
}

.OrderForm {
  margin: 0 auto;
  text-align: center;
}

.OrderForm label {
  text-align: left;
  display: block;
}

.OrderForm input {
  width: 100%;
  padding: 5px 9px;
  margin: 10px 0;
  border: 1px solid #282c34;
  box-sizing: border-box;
  display: block;
}

.Img-rotate {
  height: calc(47vmin + 95px);
  pointer-events: none;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 750px) {
  .ShoppingScreen {
    flex-direction: column;
    height: 100%;
  }
  .CartContainer, .DecorationGridArea {
    width: 95%;
  }
  .DecorationGridArea {
    height: 90vh;
    margin-bottom: 10px;
  }
}

@media only screen and (max-height: 665px) {
  .ShoppingScreen {
    flex-direction: column;
    height: 100%;
  }
  .CartContainer, .DecorationGridArea {
    width: 95%;
  }
  .DecorationGridArea {
    height: 90vh;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 320px) {
  .Nav {
    flex-direction: column;
  }
  .NavLinks {
    margin-bottom: 15px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .Img-rotate {
    animation: Img-rotate-spin infinite 20s linear;
  }
}

@keyframes Img-rotate-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
